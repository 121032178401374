<template>
  <article>
    <div class="columns is-gapless">
      <div class="column">
        <subtitle-totals v-if="innerValue"
          class="title is-6"
          :labels="[`${innerValue.prefix}${innerValue.creditNo} - Return Total`, 'GST']"
          :totals="[returnTotalAmount, gstAmount]"
          :shows="[true, true]" />
      </div>
      <div class="column">
        <div class="field is-pulled-right">
          <p class="buttons">
            <button class="button is-primary tooltip is-tooltip-topright"
              data-tooltip="Add return part item"
              @click="toggleReturnPartItemList()">
              <span class="icon">
                <i class="mdi"
                  :class="{ 'mdi-plus' : !isReturnPartItemListActive, 'mdi-minus' : isReturnPartItemListActive }" />
              </span>
              <span>{{ isReturnPartItemListActive ? 'Close' : 'Add' }}</span>
            </button>
            <!-- <button class="button is-primary tooltip is-tooltip-topright"
              data-tooltip="Add blank item"
              @click="addBlankItem">
              <span class="icon">
                <i class="mdi mdi-shape-square-plus" />
              </span>
            </button> -->
          </p>
        </div>
      </div>
    </div>
    <return-parts-item-list v-if="innerValue !== null"
      v-show="isReturnPartItemListActive"
      v-model="innerValue"
      @calculateTotal="calculateTotalLoad()"
      :is-return-part-list="isReturnPartItemListActive"
      :vendor-filter="vendorFilter" />
    <div v-show="isReturnPartItemListActive"
      class="is-divider" />
    <div style="overflow-y: auto; height: 400px;">
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :total-rows="!innerValue ? 0 : innerValue.returnPartsItem.length"
        :show-pagination="false">
        <tr v-for="(item,index) in innerValue.returnPartsItem"
          :key="item.returnPartsID"
          :class="{ 'is-selected' : selectedRow === index }">
          <td>
            <div class="has-vertical-middle">{{ item.poNumber }}</div>
          </td>
          <td>
            <div class="has-text-left">{{ item.invoiceNo }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ $filters.formatDateTimezone(item.recDate, $userInfo.locale) }}</div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.model }}</div>
          </td>
          <td width="5%">
            <vue-numeric :minus="false"
              placeholder="Qty"
              class="input has-text-right"
              :min="0"
              :disabled="true"
              v-model.number="item.rced" />
          </td>
          <td width="5%">
            <vue-numeric :minus="false"
              placeholder="Qty"
              class="input has-text-right"
              :min="0"
              :disabled="true"
              v-model.number="item.returned" />
            <!-- <div class="has-text-right">{{ item.returned }}</div> -->
          </td>
          <td width="5%">
            <vue-numeric :minus="false"
              placeholder="Qty"
              class="input has-text-right"
              :min="0"
              :disabled="readOnly"
              @blur="calculateTotal(item,index)"
              v-model.number="item.returning" />
          </td>
          <td width="12%">
            <vue-numeric :minus="false"
              placeholder="Amount Required"
              class="input has-text-right"
              :min="0.00"
              :precision="2"
              :disabled="!item.isNewLine"
              @blur="calculateTotal(item,index)"
              v-model.number="item.totalAmount" />
          </td>
          <td>
            <div class="has-vertical-middle">
              <input class="input"
                :disabled="readOnly"
                v-model="item.partNo"
                type="text"
                placeholder="Part No.">
            </div>
          </td>
          <td>
            <div class="has-vertical-middle">
              <input class="input"
                v-model="item.script"
                :disabled="!item.isNewLine"
                type="text"
                placeholder="Description">
            </div>
          </td>
          <td>
            <div class="has-vertical-middle">{{ item.quoteNo }}</div>
          </td>
          <td class="has-vertical-middle has-text-centered is-content-width">
            <a class="button is-danger is-small is-inverted"
              :disabled="!item.isNewLine"
              @click="deleteItem(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </a>
          </td>
        </tr>
        <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-36px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </template>
        <!-- <tfoot>
          <tr>
            <th class="has-text-left">Banking Total </th>
            <th class="has-text-right has-text-success">{{ value | formatCurrency($userInfo.locale) }}</th>
          </tr>
        </tfoot> -->
      </bulma-table>
    </div>
  </article>
</template>

<script>
// import DealerCreditEntryService from './BankUndepositedFundService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import ReturnPartsItemList from './ReturnPartsItemList'
import { ReturnPartsDetail } from './columns'
import VueNumeric from '@/components/VueNumeric'
import _cloneDeep from 'lodash/cloneDeep'
import { ReturnItemPartsModel } from '@/classes/viewmodels'
import Guid from '@/components/Guid'
import SubtitleTotals from '@/components/SubtitleTotals'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'ReturnPartsDetail',
  components: {
    [ReturnPartsItemList.name]: ReturnPartsItemList,
    BulmaTable,
    SubtitleTotals,
    VueNumeric
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    vendorDetail: {
      type: Object
    },
    vendorFilter: {
      type: Object
    },
    readOnly: Boolean,
    value: Object
  },
  data() {
    return {
      emptyGuid: Guid.empty(),
      innerValue: _cloneDeep(this.value),
      isTableLoading: false,
      totalRows: 0,
      isReturnPartItemListActive: true,
      returnTotalAmount: 0.0,
      gstAmount: 0.0,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      selectedRow: null
    }
  },
  computed: {
    $v() {},
    columns() {
      return ReturnPartsDetail
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  async created() {
    // this.getData()
  },
  mounted() {},
  methods: {
    toggleReturnPartItemList() {
      this.isReturnPartItemListActive = !this.isReturnPartItemListActive
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    addBlankItem() {
      const newItem = new ReturnItemPartsModel(this.innerValue.vendorId)
      this.innerValue.returnPartsItem.push(newItem)
    },
    calculateTotalLoad(){
      this.returnTotalAmount = 0.0
      this.gstAmount = 0.0
      for (let i = 0; i < this.innerValue.returnPartsItem.length; i++) {
        if (this.innerValue.returnPartsItem[i].returning > 0) {
          this.returnTotalAmount = roundAwayFromZero(this.returnTotalAmount + (this.innerValue.returnPartsItem[i].returning * this.innerValue.returnPartsItem[i].invcAmount), 2)
        }
      }
      if(this.returnTotalAmount > 0)
      {
        if(this.innerValue.gstDivisor > 0)
        {
          this.gstAmount = roundAwayFromZero(this.returnTotalAmount / this.innerValue.gstDivisor, 2)
        }
        else
        {
          this.gstAmount = 0.0
        }
      }
    },
    calculateTotal(item, index){
      this.returnTotalAmount = 0.0
      this.gstAmount = 0.0
      for (let i = 0; i < this.innerValue.returnPartsItem.length; i++) {
        if (this.innerValue.returnPartsItem[i].returning > 0) {
          this.returnTotalAmount = roundAwayFromZero(this.returnTotalAmount + (this.innerValue.returnPartsItem[i].returning * this.innerValue.returnPartsItem[i].invcAmount), 2)
        }
      }

      if(this.returnTotalAmount > 0)
      {
        if(this.innerValue.gstDivisor > 0)
        {
          this.gstAmount = roundAwayFromZero(this.returnTotalAmount / this.innerValue.gstDivisor, 2)
        }
        else
        {
          this.gstAmount = 0.0
        }
      }
      if(this.innerValue.returnPartsItem[index].isNewLine === true)
      {
        this.innerValue.returnPartsItem[index].totalAmount = roundAwayFromZero(this.innerValue.returnPartsItem[index].returning * this.innerValue.returnPartsItem[index].invcAmount, 2)
      }
      else
      {
        this.innerValue.returnPartsItem[index].totalAmount = roundAwayFromZero(this.innerValue.returnPartsItem[index].returning * this.innerValue.returnPartsItem[index].invcAmount, 2)
      }
    },
    deleteItem(item, index) {
      if (item.isNewLine) {
        const itemIndex = this.innerValue.returnPartsItem
          .map(function (obj) {
            return obj.returnPartsID
          })
          .indexOf(item.returnPartsID)
        if (itemIndex >= 0) {
          this.innerValue.returnPartsItem.splice(itemIndex, 1)
        }
      }
    }
  }
}
</script>
