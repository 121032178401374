<template>
  <div>
    <div class="field">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field-body">
            <div class="field has-addons">
              <div class="control has-icons-left has-icons-right">
                <input class="input"
                  v-model="filter.query"
                  type="text"
                  placeholder="Start typing to filter"
                  @input="search">
                <span class="icon is-left">
                  <i class="mdi mdi-magnify mdi-18px" />
                </span>
              </div>
              <div class="control">
                <a class="button is-primary is-outlined"
                  @click="reset()">
                  <span class="icon">
                    <i class="mdi mdi-close mdi-18px" />
                  </span>
                </a>
              </div>
            </div>
            <v-date-picker v-model="receiptDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="receiptDate = $filters.fromISOWithCurrentTime($event.toISOString())"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input v-if="isShowReceipt"
                      type="text"
                      class="input"
                      :disabled="true"
                      :value="$filters.formatDateTimezone(receiptDate, $userInfo.locale)"
                      placeholder="Receipts dated from">
                    <input v-else
                      type="text"
                      class="input"
                      :value="inputValue"
                      v-on="inputEvents"
                      placeholder="Receipts dated from">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="control field">
          <input class="is-checkradio is-box is-primary"
            :class="{ 'has-background-color': isShowReceipt }"
            id="showReceipt"
            name="showReceipt"
            type="checkbox"
            :disabled="vendorFilter.vendorId === emptyGuid"
            v-model="isShowReceipt">
          <label class="label"
            for="showReceipt">Show All Receipts</label>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      @pageChanged="onPageChange"
      :show-pagination="true">
      <tr v-for="(item,index) in returnPartsItems"
        :key="item.poItemID"
        @dblclick="isAddItem(item, index) ? addNewItem(item, index) : '' "
        :class="{ 'is-selected' : selectedRow === index }">
        <td>
          <div class="has-vertical-middle">{{ item.poNumber }}</div>
        </td>
        <td>
          <div class="has-text-left">{{ item.invoiceNo }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ $filters.formatDateTimezone(item.recDate, $userInfo.locale) }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.model }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.rced }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.returned }}</div>
        </td>
        <td>
          <div class="has-vertical-middle"> {{ item.returning }} </div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.totalAmount | formatNumber($userInfo.locale) }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.partNo }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.script }}</div>
        </td>
        <td>
          <div class="has-vertical-middle">{{ item.quoteNo }}</div>
        </td>
        <td>
          <div class="has-text-centered"
            v-show="isAddItem(item, index)">
            <a class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
              data-tooltip="Click or double-click row to add item"
              @click="addNewItem(item, index)">
              <span class="icon is-medium">
                <i class="mdi mdi-plus mdi-24px" />
              </span>
            </a>
          </div>
        </td>
      </tr>
      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-36px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import ReturnPartsService from './ReturnPartsService'
import _debounce from 'lodash.debounce'
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import Guid from '@/components/Guid'
// import { calcTotalExGst, calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import BulmaTable from '@/components/BulmaTable'
import { ReturnPartsItems } from './columns'
import { ReturnItemPartsModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ReturnPartsItemList',
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: Object,
    vendorFilter: {
      type: Object
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      returnPartsItems: [],
      totalRows: 0,
      receiptDate: new Date().toISOString(),
      emptyGuid: Guid.empty(),
      filter: {
        query: '',
        isReceiptDate: true,
        receiptDate: '',
        vendorId: null,
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 4
      },
      isShowReceipt: true,
      selectedRow: null,
      isTableLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    columns() {
      return ReturnPartsItems
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
        this.$emit('input', newVal)
      },
      deep: true
    },
    receiptDate: function (newVal, oldVal) {
      if (newVal) {
        this.filter.receiptDate = this.receiptDate.toISOString()
      }
      this.getData()
    },
    isShowReceipt: function (newVal, oldVal) {
      if (newVal) {
        this.receiptDate = new Date()
        this.filter.receiptDate = this.receiptDate.toISOString()
        this.filter.isReceiptDate = true
      } else {
        this.receiptDate = new Date()
        this.receiptDate.setMonth(this.receiptDate.getMonth() - 6)
        this.filter.receiptDate = this.receiptDate.toISOString()
        this.filter.isReceiptDate = false
      }
      this.getData()
    },
    vendorFilter: {
      handler: function (newVal, oldVal) {
        this.filter.vendorId = newVal.vendorId
        // this.innerValue.vendorId = this.vendorFilter.vendorId
        if (newVal !== null) {
          this.getData()
        }
      },
      deep: true
    }
  },
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    async getData() {
      this.filter.vendorId = this.vendorFilter.vendorId === null ? Guid.empty() : this.vendorFilter.vendorId
      this.isTableLoading = true
      this.returnPartsItems = await ReturnPartsService.getReturnPartsItems(this.filter)

      if (this.returnPartsItems) {
        // this.innerValue.returnPartsListItem = this.returnPartsItems
        this.totalRows = this.returnPartsItems.length > 0 ? this.returnPartsItems[0].totalRows : 0
      } else {
        this.totalRows = 0
      }
      this.isTableLoading = false
      this.$emit('calculateTotal')
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getData()
    },
    reset() {
      this.filter.pageIndex = 1
      this.filter.query = ''
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.getData()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getData()
    }, 500),
    addBlankItem() {
      const newItem = new ReturnItemPartsModel(this.innerValue.vendorId)
      this.value.returnPartsItem.push(newItem)
    },
    addNewItem(item, index) {
      // Find item by item no
      let itemExist = this.innerValue.returnPartsItem.filter((i) => i.poItemId === item.poItemID)

      if(itemExist.length !== 0) {
        this.$notification.openNotificationWithType('warning', 'Return Parts', 'Item is already in selected list. Item not added.')
      }else if (this.innerValue.returnPartsItem.length !== 0 && item.poNumber !== this.innerValue.returnPartsItem[0].poNumber)  {
        this.$notification.openNotificationWithType('warning', 'Return Parts', 'Item can not be addedd to different purchase order no')
      } else {
        const newItem = new ReturnItemPartsModel(
          item.vendorId,
          item.crReturnNo,
          item.creditReturnId,
          item.creditorInvoiceId,
          item.invcAmount,
          item.invoiceNo,
          item.invoiceRef,
          item.model,
          item.partNo,
          item.poItemID,
          item.poNumber,
          item.poRetQty,
          item.qtyCredited,
          item.quoteNo,
          item.recDate,
          item.rced,
          item.return,
          item.returnPartsID,
          item.returned,
          item.returning,
          item.script,
          item.totalAmount,
          item.totalReturned
        )
        this.innerValue.returnPartsItem.push(newItem)
      }
    },
    isAddItem(item, index) {
      if(this.innerValue.returnPartsItem.length !== 0){
        let itemExist = this.innerValue.returnPartsItem.filter((i) => i.poItemId === item.poItemID)
        if(itemExist.length !== 0 || item.poNumber !== this.innerValue.returnPartsItem[0].poNumber) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  }
}
</script>
